import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import './App.css';

function App() {
  const [compras, setCompras] = useState([]);
  const [search, setSearch] = useState('');
  const [filterCanton, setFilterCanton] = useState(null);
  const [filterProvincia, setFilterProvincia] = useState(null);
  const [filterEntidad, setFilterEntidad] = useState(null);
  const [filterCategoria, setFilterCategoria] = useState(null);
  const [cantones, setCantones] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [entidades, setEntidades] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    axios.get('http://localhost:5000/api/compras')
      .then(response => {
        const sortedData = response.data.sort((a, b) => new Date(b.fecha_publicacion) - new Date(a.fecha_publicacion));
        setCompras(sortedData);
        const uniqueCantones = [...new Set(response.data.map(compra => compra.canton))];
        const uniqueProvincias = [...new Set(response.data.map(compra => compra.provincia))];
        const uniqueEntidades = [...new Set(response.data.map(compra => compra.entidad))];
        setCantones(uniqueCantones.map(canton => ({ value: canton, label: canton })));
        setProvincias(uniqueProvincias.map(provincia => ({ value: provincia, label: provincia })));
        setEntidades(uniqueEntidades.map(entidad => ({ value: entidad, label: entidad })));
      
        // Obtener categorías únicas
        const categoriasData = response.data.map(compra => categorizeDescription(compra.descripcion));
        const uniqueCategorias = [...new Set(categoriasData)];
        setCategorias(uniqueCategorias.map(categoria => ({ value: categoria, label: categoria })));
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
      });
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); // Ajuste por zona horaria
    return format(date, 'dd/MM/yyyy');
  };

  const categorizeDescription = (description) => {
    const categories = {
      'Tecnología': ['software', 'hardware', 'sistema'],
      'Construcción': ['construcción', 'edificio', 'obra'],
      'Servicios': ['consultoría', 'mantenimiento', 'limpieza'],
      'Póliza de Seguros': ['póliza de seguros', 'seguros', 'póliza'],
      'Combustible': ['combustible', 'diesel', 'gasolina']
    };

    for (const [category, keywords] of Object.entries(categories)) {
      for (const keyword of keywords) {
        if (description.toLowerCase().includes(keyword.toLowerCase())) {
          return category;
        }
      }
    }
    return 'Otros';
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleFilterCantonChange = (selectedOption) => {
    setFilterCanton(selectedOption);
  };

  const handleFilterProvinciaChange = (selectedOption) => {
    setFilterProvincia(selectedOption);
  };

  const handleFilterEntidadChange = (selectedOption) => {
    setFilterEntidad(selectedOption);
  };

  const handleFilterCategoriaChange = (selectedOption) => {
    setFilterCategoria(selectedOption);
  };

  const filteredCompras = compras.filter(compra => {
    const matchesSearch = compra.descripcion.toLowerCase().includes(search.toLowerCase());
    const matchesCanton = filterCanton ? compra.canton === filterCanton.value : true;
    const matchesProvincia = filterProvincia ? compra.provincia === filterProvincia.value : true;
    const matchesEntidad = filterEntidad ? compra.entidad === filterEntidad.value : true;
    const matchesCategoria = filterCategoria ? categorizeDescription(compra.descripcion) === filterCategoria.value : true;
    return matchesSearch && matchesCanton && matchesProvincia && matchesEntidad && matchesCategoria;
  });

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const pageCount = Math.ceil(filteredCompras.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentPageData = filteredCompras.slice(offset, offset + itemsPerPage);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '4px',
      borderColor: '#ddd',
      marginRight: '10px',
      marginBottom: '10px',
      width: '500px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#f4f4f4' : '#fff',
      color: '#333',
      '&:hover': {
        backgroundColor: '#f9f9f9',
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '4px',
    }),
  };

  return (
    <div className="App">
      <h1>ProformaSearch</h1>
      <h2>Sistema de Busqueda de Necesidades de Contratación y Recepción de Proformas Ínfima Cuantías</h2>
      <div className="filters">
        <input 
          type="text" 
          placeholder="Buscar por descripción" 
          value={search} 
          onChange={handleSearchChange} 
        />
        <Select 
          placeholder="Todas las Provincias"
          value={filterProvincia}
          onChange={handleFilterProvinciaChange}
          options={provincias}
          isClearable
          styles={customStyles}
          isSearchable
        />
        <Select 
          placeholder="Todos los Cantones"
          value={filterCanton}
          onChange={handleFilterCantonChange}
          options={cantones}
          isClearable
          styles={customStyles}
          isSearchable
        />
        <Select 
          placeholder="Todas las Entidades"
          value={filterEntidad}
          onChange={handleFilterEntidadChange}
          options={entidades}
          isClearable
          styles={customStyles}
          isSearchable
        />
        <Select 
          placeholder="Todas las Categorías"
          value={filterCategoria}
          onChange={handleFilterCategoriaChange}
          options={categorias}
          isClearable
          styles={customStyles}
          isSearchable
        />
      </div>
      
      <table>
        <thead>
          <tr>
            <th>Código de Necesidad</th>
            <th>Fecha de Publicación</th>
            <th>Provincia</th>
            <th>Cantón</th>
            <th>Descripción del Objeto</th>
            <th>Categoría</th>
            <th>Estado de la Necesidad</th>
            <th>Fecha Límite</th>
            <th>Entidad Contratante</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          {currentPageData.map((compra, index) => (
            <tr key={index}>
              <td>{compra.codigo}</td>
              <td>{formatDate(compra.fecha_publicacion)}</td>
              <td>{compra.provincia}</td>
              <td>{compra.canton}</td>
              <td>{compra.descripcion}</td>
              <td>{categorizeDescription(compra.descripcion)}</td>
              <td>{compra.estado}</td>
              <td>{formatDate(compra.fecha_limite)}</td>
              <td>{compra.entidad}</td>
              <td><a href={compra.link}>Ver Proceso</a></td>
            </tr>
          ))}
        </tbody>
      </table>

      <ReactPaginate
        previousLabel={'anterior'}
        nextLabel={'siguiente'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />


      <footer className="footer">
        <p>Desarrollado por Zire Consulting Services</p>
        <p>&copy; {new Date().getFullYear()} Todos los derechos reservados. Propiedad intelectual.</p>
      </footer>
    </div>
  );
}

export default App;